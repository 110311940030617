import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

const VueInstance = createApp(App)

VueInstance.use(router);
VueInstance.config.performance = false

VueInstance.mount('#app');
