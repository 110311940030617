/**
 * gets a list of all the images located in ~/src/public/assets/header_imgs/ in the .jpg format
 * and returns an array called 'image' which is a list of urls that point to ~/dist/assets/header_imgs/filename.jpg,
 * which is where images are stored after compilation
 */
// const mode = process.env.NODE_ENV
// console.log(`from slides.ts, production is currently: `, mode)
export default require.context(`../../../public/assets/header_imgs/`, false, /^.*\.jpg$/).keys().map(fileName => {
    return {
        image: `/assets/header_imgs/${fileName.slice(2)}`
    }
})