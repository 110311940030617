import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4d481d16"),n=n(),_popScopeId(),n)
const _hoisted_1 = { ref: "slideshow" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueperSlide = _resolveComponent("VueperSlide")!
  const _component_VueperSlides = _resolveComponent("VueperSlides")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_VueperSlides, {
      fade: "",
      autoplay: "",
      arrows: false,
      bullets: false,
      duration: 3000,
      touchable: false,
      "transition-speed": 1500,
      parallax: 1,
      "fixed-height": "100%"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slides, (slide, i) => {
          return (_openBlock(), _createBlock(_component_VueperSlide, {
            key: i,
            image: slide.image
          }, null, 8, ["image"]))
        }), 128))
      ]),
      _: 1
    })
  ], 512))
}