import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-601ce552"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "gal-container" }
const _hoisted_3 = ["src", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.modalOpen)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "modal-viewer",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.checkToClose($event))),
          ref: "modalWindow"
        }, [
          _createElementVNode("span", {
            class: "arrow",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.prevModalImage()))
          }, "ᐸ"),
          _createElementVNode("img", {
            class: "modal-image",
            src: _ctx.modalImage,
            alt: "",
            onTouchstart: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleTouchStart && _ctx.handleTouchStart(...args))),
            onTouchend: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleTouchEnd && _ctx.handleTouchEnd(...args)))
          }, null, 40, _hoisted_1),
          _createElementVNode("span", {
            class: "arrow",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.nextModalImage()))
          }, "ᐳ")
        ], 512))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentImages, (image, index) => {
        return (_openBlock(), _createElementBlock("figure", {
          class: "gal-item",
          key: image
        }, [
          _createElementVNode("img", {
            class: "gal-image",
            src: image,
            alt: "",
            onLoad: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.imageHasLoaded && _ctx.imageHasLoaded(...args))),
            onClick: ($event: any) => (_ctx.openModal(index))
          }, null, 40, _hoisted_3)
        ]))
      }), 128))
    ])
  ], 64))
}