
import { defineComponent, computed, ref, watch, onMounted } from 'vue'

export default defineComponent({
    name: 'gallery',
    inheritAttrs: false,
    props: {
        images: {
            type: Array,
            required: true
        }
    },
    setup(props){
        const currentImages = computed(() => props.images as [string])
        const loadTracker = ref(0)
        //let loading = true
        //const isLoading = computed(() => loading)

        const galleryHasLoaded = function(){
            console.log('you should only see this I think when all the images have loaded in?')
        }

        const imageHasLoaded = function(){
            loadTracker.value++
            console.log('a image has loaded')
            if(loadTracker.value == props.images.length){
                //loading = false
                console.log('the gallery has fully loaded')
            }
        }

        watch(currentImages, (newVal) => {
            if(newVal){
                //loading = true
                loadTracker.value = 0;
            }
        })

        const modalStateAndMethods = {
            modalImage: ref(""),
            modalIndex: ref(-1),
            modalOpen: ref(false),
            modalWindow: ref<HTMLElement>(),
            touchEvent: {
                xDown: -1,
                yDown: -1,
            },

            openModal: function(index:number){
                if(!modalStateAndMethods.modalOpen.value){
                    modalStateAndMethods.modalOpen.value = true;
                    modalStateAndMethods.modalImage.value = currentImages.value[index];
                    modalStateAndMethods.modalIndex.value = index
                }
                console.log(`request to open image ${modalStateAndMethods.modalImage.value} which should be at index ${index}`)
            },

        /**
         * TODO: 
         * style image in modal
         * clickoutside image to close modal?
         */

            nextModalImage: function(){
                if(modalStateAndMethods.modalIndex.value != -1){
                    if( (modalStateAndMethods.modalIndex.value + 1) > (props.images.length - 1) ){
                        modalStateAndMethods.closeModal()
                    } else {
                        modalStateAndMethods.modalIndex.value++
                        modalStateAndMethods.modalImage.value = currentImages.value[modalStateAndMethods.modalIndex.value]
                    }
                }
            },

            prevModalImage: function(){
                if(modalStateAndMethods.modalIndex.value != -1){
                    if( (modalStateAndMethods.modalIndex.value - 1) < 0 ){
                        modalStateAndMethods.closeModal()
                    } else {
                        modalStateAndMethods.modalIndex.value--
                        modalStateAndMethods.modalImage.value = currentImages.value[modalStateAndMethods.modalIndex.value]
                    }
                }
            },

            closeModal: function(){
                if(modalStateAndMethods.modalOpen.value){
                    modalStateAndMethods.modalOpen.value = false;
                    modalStateAndMethods.modalIndex.value = -1;
                } else {
                    return;
                }
            },

            checkToClose: function(event: Event){
                console.log(`modalClicked, and target is: `, event.target)
                if(event.target instanceof Element){
                    if(event.target.classList.contains('modal-viewer')){
                        modalStateAndMethods.closeModal()
                    }
                }
            },

            handleKeyPress: function(event: Event){
                if(event instanceof KeyboardEvent){
                    switch (event.code) {
                        case "ArrowLeft":
                            modalStateAndMethods.prevModalImage()
                            break;
                        case "ArrowRight":
                            modalStateAndMethods.nextModalImage()
                            break
                        case "Escape":
                            modalStateAndMethods.closeModal()
                            break;
                        default:
                            console.log(`no case match, keypress is: `, event.code)
                            break;
                    }
                }
            },

            handleTouchStart: function(event: Event) {
                if(event instanceof TouchEvent){
                    const touches = event.touches

                    if(touches.length === 2){
                        //user is likely "pinching", so ignore and return
                        return;
                    } else {
                        event.stopPropagation();
                        event.preventDefault();

                        modalStateAndMethods.touchEvent.xDown = touches[0].clientX
                        modalStateAndMethods.touchEvent.yDown = touches[0].clientY
                    }

                }
            },

            handleTouchEnd: function(event: Event){
                if(event instanceof TouchEvent){
                    if(
                        !modalStateAndMethods.touchEvent.xDown || 
                        !modalStateAndMethods.touchEvent.yDown ||
                        event.changedTouches.length === 2){
                        return
                    } else {
                        event.stopPropagation();
                        event.preventDefault();

                        const touches = event.changedTouches

                        const xDiff = touches[0].clientX - modalStateAndMethods.touchEvent.xDown

                        if(xDiff > 10){
                            modalStateAndMethods.prevModalImage()
                        } else if( xDiff < -10) {
                            modalStateAndMethods.nextModalImage()
                        } else {
                            return;
                        }
                    }
                }
            }
        }

        onMounted(() => {
            document.addEventListener('keydown', (event) => {
                if(modalStateAndMethods.modalOpen.value){
                    modalStateAndMethods.handleKeyPress(event)
                }
            })
        })


        return {
            currentImages,
            galleryHasLoaded,
            imageHasLoaded,
            loadTracker,
            ...modalStateAndMethods
        }
    }
})
