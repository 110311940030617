import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-671b8e7c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "properties" }
const _hoisted_2 = { class: "tabs" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gallery = _resolveComponent("gallery")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.addressNames, (address) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["propertyButton", address == _ctx.currentProperty ? `propertyButtonSelected` : ``]),
          key: address,
          onClick: ($event: any) => (_ctx.changeProperty(address))
        }, [
          _createElementVNode("span", null, _toDisplayString(address), 1)
        ], 10, _hoisted_3))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("span", null, _toDisplayString(_ctx.currentPropertyObj.description), 1)
    ]),
    _createVNode(_component_gallery, {
      class: "gallery",
      images: _ctx.currentPropertyObj.images
    }, null, 8, ["images"])
  ]))
}