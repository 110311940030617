 /* eslint-disable */

 /**
  * exports an object called Properties, which is a group of Property objects (see interface below)
  * 
  * This is used in Properties.vue to display the selected property without having to hard-code each description and set of images 
  */
interface Property {
    description: string,
    images: Array<string> | any
}

const willardInString = "/assets/willard_interior/";
const fivejcksonString = "/assets/5jckson/";
const seventjcksonString = "/assets/7jckson/";
/**
 * lets see if we can't automatically copy the images from the directory using webpack so we can have an always up-to-date images list:
 * https://webpack.js.org/plugins/copy-webpack-plugin/ ???
 * https://webpack.js.org/guides/dependency-management/#requirecontext <-- what we use below
 * https://cli.vuejs.org/guide/html-and-static-assets.html#when-to-use-the-public-folder
 * 
 * https://stackoverflow.com/questions/48850155/vue-js-webpack-how-to-get-the-list-of-files-in-a-directory
 * 
 */

const willardArray = require.context(`../../../public/assets/willard_interior`, false, /^.*\.jpg$/).keys().map(fileName => `${willardInString}${fileName.slice(2)}`)
const fiveArray = require.context(`../../../public/assets/5jckson/`, false, /^.*\.jpg$/).keys().map(fileName => `${fivejcksonString}${fileName.slice(2)}`)
const sevenArray = require.context(`../../../public/assets/7jckson/`, false, /^.*\.jpg$/).keys().map(fileName => `${seventjcksonString}${fileName.slice(2)}`)

export const Properties: Record<string, Property> = {
    '196 S. Willard st.': {
        description: `Light filled on corner lot, 3 bedroom, 2.5 bath unit renovated in 2019. Spacious first floor features eat-in kitchen w/ all new appliances, half-bath & laundry off entry. West facing kitchen enjoys lots of natural light & seasonal views. LR includes gas fireplace. Contemporary open staircase leads to an upstairs library/play space which enjoys seasonal lake views along with 2 of the 3 bedrooms. Great location directly across from Champlain College, one block to Edmunds Elementary/MS, three blocks to UVM, City Market, YMCA, & theaters. Light-filled townhouse is perfect for families & professionals wanting extra space with a walkable lifestyle. Amenities include:

- in-unit washer dryer
- dishwasher
- off-street parking for 2 cars
- plenty of basement storage
- Burlington Telecom & Comcast ready
- trash / compost / snow removal / water included.
- AC in most rooms

Dogs & cats allowed w/ deposit. Tenant pays for electric and data. 
Perfect space for young professionals, medical & graduate students, & faculty.`,
        images: willardArray
    },
    '5 Jackson Ct.': {
        description: `Cozy furnished 1 bedroomn / 1.5 bathroom townhouse. Amenities include:

- fully furnished with a king-sized bed in the bedroom
- in-unit washer/dryer
- recently renovated kitchen including dishwasher
- off-street parking for 2 cars
- wired for either Burlington Telecom or Comcast
- heat / electricity / internet included
- trash / compost / snow removal also included
- air conditioning in most spaces

Dogs / cats allowed with deposit.
Perfect space for young professionals, medical & graduate students, & faculty.`,
        images: fiveArray
    },
    '7 Jackson Ct.': {
        description: `Architecturally striking 3 bedroom, 2 bath unit with a flexible floor plan. The 1st floor features a flexible suite with full bath, kitchen, and family area. Contemporary south facing window wall creates light filled spaces on both levels. The architectural stair case leads up to 2 bedrooms, a full bath with tub, and laundry closet. Great location across from Champlain College, one block to Edmunds Elementary & Middle Schools, and three blocks to UVM, City Market, YMCA, Roxy & Flynn theaters. Location perfect for a walkable lifestyle. Amenities include:

- in-unit washer/dryer
- recently renovated kitchen including dishwasher & disposal
- off-street parking for 2 cars
- wired for either Burlington Telecom or Comcast
- hot water / trash / compost / snow removal
- air conditioning in most spaces

Dogs / cats allowed with deposit. Tenant pays for electric & data. 
Perfect space for young professionals, medical & graduate students, & faculty.`,
        images: sevenArray
    }
}

export const addressNames = Object.keys(Properties);

