
    import { defineComponent, ref } from 'vue'
    import slides from './plugins/slides'

    import { VueperSlides, VueperSlide } from 'vueperslides/dist/vueperslides.common'
    import 'vueperslides/dist/vueperslides.css'

    export default defineComponent({
        name: 'slideshow',
        components: { VueperSlide, VueperSlides },
        setup(){
            const slideshow = ref<HTMLElement>()

            return {
                slideshow,
                slides,
            }
        }
    })
