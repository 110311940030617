
    import { defineComponent, reactive, computed, toRefs } from 'vue'
    import gallery from './gallery.vue'
    import { Properties, addressNames } from './plugins/properties'

    export default defineComponent({
        name: 'properties',
        components: { 
            gallery 
        },
        setup(){
            const state = reactive({
                currentProperty: addressNames[0],
            })

            const currentPropertyObj = computed(() => Properties[state.currentProperty])
            //const buttonStyle = computed(() => )

            const changeProperty = function(newProperty: string){
                state.currentProperty = newProperty;
            }
            
            return {
                addressNames,
                ...toRefs(state),
                currentPropertyObj,
                changeProperty
            }
        }
    })

