
  import { defineComponent } from 'vue'

  import headerTest from '../components/Header.vue'
  import Properties from '../components/Properties.vue'

  export default defineComponent({
    name: 'Home',
    components: {
      headerTest,
      Properties
    },
  });
