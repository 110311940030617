
    import { defineComponent, ref } from 'vue'

    import slideshow from './slideshow.vue'

    export default defineComponent({
        name: "headerTest",
        components: {
            slideshow
        },
        setup(){ 
            const headerRef = ref<HTMLElement>()

            return {
                headerRef
            }
        }
    })
